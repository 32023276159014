.home {	
	#masthead {
		background: $black;
		&:before {
			display: none;
		}
		height: 100vh;
		padding: 0;
		align-items: flex-end;

		h1 {
			// font-size: 4.5em;
			width: 62%;
		}

		video {
			position: absolute;
			width: 100%;
			height: 100%;
			@include opacity(.5);
			position: absolute;
			object-fit: cover;
		}

		@include media-query($on-palm) {
			// height: 100vw;
			align-items: center;
			h1 {
				font-size: 2.4em;
				width: 100%;
			}
		}
	}
}
