/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
	margin: 0;
	padding: 0;
}



/**
 * Basic styling
 */
body {
	@include typeset($base-font-family, $base-font-weight, $base-font-size, $base-line-height, 0);
	color: $text-color;
	background-color: $background-color;
	-webkit-text-size-adjust: 100%;
	-webkit-font-feature-settings: "kern" 1;
		 -moz-font-feature-settings: "kern" 1;
			 -o-font-feature-settings: "kern" 1;
					font-feature-settings: "kern" 1;
	font-kerning: normal;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
	margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
	max-width: 100%;
	vertical-align: middle;
}



/**
 * Figures
 */
figure > img {
	display: block;
}

figcaption {
	font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
	margin-left: $spacing-unit;
}

li {
	> ul,
	> ol {
		margin-bottom: 0;
	}
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
}
h1 {
	@include typeset($display-font-family, $display-font-weight, 3.9em, 1.1, -0.04em);
	a {
		font-weight: $display-font-weight;
	}
	@include media-query($on-laptop) {
		font-size: 3.0em;
	}
	@include media-query($on-palm) {
		font-size: 2.6em;
	}
}
h2 {
	@include typeset($display-font-family, $display-font-weight, 2.6em, 1.1, 0);
	@include media-query($on-laptop) {
		font-size: 2.0em;
	}
	@include media-query($on-palm) {
		font-size: 1.8em;
	}
}
h3 {
	@include typeset($display-font-family, $display-font-weight, 1.5em, 1.1, 0.33em);
	text-transform: uppercase;
	@include media-query($on-laptop) {
		font-size: 1.25em;
	}
	@include media-query($on-palm) {
		font-size: 1.0em;
	}
}
h4 {
	@include typeset($display-font-family, $display-font-weight, 1.2em, 1.1, 0);
}
h5 {
	@include typeset($display-font-family, $display-font-weight, 0.85em, 1.1, 0);
}


/**
 * Links
 */
a {
	color: $brand-color;
	text-decoration: none;

	&:visited {
		color: darken($brand-color, 15%);
	}

	&:hover {
		color: $text-color;
		text-decoration: underline;
	}
}



/**
 * Blockquotes
 */
blockquote {
	color: $grey-color;
	border-left: 4px solid $grey-color-light;
	padding-left: $spacing-unit / 2;
	font-size: 18px;
	letter-spacing: -1px;
	font-style: italic;

	> :last-child {
		margin-bottom: 0;
	}
}



/**
 * Code formatting
 */
pre,
code {
	font-size: 15px;
	border: 1px solid $grey-color-light;
	border-radius: 3px;
	background-color: #eef;
}

code {
	padding: 1px 5px;
}

pre {
	padding: 8px 12px;
	overflow-x: auto;

	> code {
		border: 0;
		padding-right: 0;
		padding-left: 0;
	}
}


/**
 * Wrapper
 */
 .wrapper {
	position: relative;
	// max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
	// max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
	width: 100%;
	max-width: $content-width;
	margin-right: auto;
	margin-left: auto;
	// padding-right: $spacing-unit;
	// padding-left: $spacing-unit;
	@extend %clearfix;
	box-sizing: border-box;

	// @include media-query($on-laptop) {
	//   padding-right: $spacing-unit / 1;
	//   padding-left: $spacing-unit / 1;
	// }

	@include media-query($on-palm) {
	//   padding-right: $spacing-unit / 2;
	//   padding-left: $spacing-unit / 2;
	}
}

.masthead,#masthead {
	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: $brand-color;
		background-size: cover;
		background-position: center;
		opacity: 0.5;
		z-index: 0;
	}
	background: $black;
	position: relative;
	height: 25vw;
	color: $gray-900;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: content-box;
	padding-top: 64px;
	@include media-query($on-palm) {
		height: 50vw;
	}

		h1 {
			position: relative;
			z-index: 10;
			color: $white;
		}
}



/**
 * Clearfix
 */
%clearfix:after {
	content: "";
	display: table;
	clear: both;
}



/**
 * Icons
 */
.icon > svg {
	display: inline-block;
	vertical-align: middle;

	path {
		fill: $grey-color;
	}
}
