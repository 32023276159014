// Define defaults for each variable.

$base-font-family: "DIN","Roboto","Helvetica Neue", Helvetica, Arial, sans-serif !default;
$base-font-size:   21px !default;
$base-font-weight: 200 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.75 !default;

$heading-font-family: $base-font-family;
$heading-font-weight: 400;
$display-font-family: $base-font-family;
$display-font-weight: 200;

$spacing-unit:     30px !default;

$background-color: #fdfdfd !default;
$brand-color:      #007dc6 !default;
$text-color:       hsl(hue($brand-color),50%,25%) !default;

$white:                 hsl(hue($brand-color), 2%,98%);
$gray-900:              hsl(hue($brand-color), 5%,89%);
$gray-800:              hsl(hue($brand-color), 8%,78%);
$gray-500:              hsl(hue($brand-color),13%,50%);
$gray-400:              hsl(hue($brand-color),17%,42%);
$gray-300:              hsl(hue($brand-color),21%,31%);
$gray-200:              hsl(hue($brand-color),28%,20%);
$gray-100:              hsl(hue($brand-color),34%,12%);
$black:                 hsl(hue($brand-color),55%, 4%);


$grey-color:       $gray-500 !default;
$grey-color-light: $gray-800 !default;
$grey-color-dark:  $gray-200 !default;


// Width of the content area
$content-width:    80vw !default;//800px !default;

$on-palm:          480px !default;
$on-laptop:        1024px !default;

$animate-speed:     200ms !default;
$animate-equation:  ease-in-out;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}


@font-face {
    font-family:"DIN";
    font-weight: 200;
    src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Light.eot');
    src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/DIN-Pro/PFDinTextPro-Light.ttf') format('truetype');
    src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Light.woff') format('woff');
}
@font-face {
    font-family:"DIN";
    font-weight: 400;
    src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Regular.eot');
    src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/DIN-Pro/PFDinTextPro-Regular.ttf') format('truetype');
    src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Regular.woff') format('woff');
}
// @font-face {
//     font-family:"DIN";
//     font-weight: 800;
//     src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Bold.eot');
//     src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Bold.eot?#iefix') format('embedded-opentype'),
//         url('/assets/fonts/DIN-Pro/PFDinTextPro-Bold.ttf') format('truetype');
//     src:url('/assets/fonts/DIN-Pro/PFDinTextPro-Bold.woff') format('woff');
// }
@font-face {
    font-family:"ICONS";
    font-weight: 200;
    src:url('/assets/fonts/ElegantIcons.eot');
    src:url('/assets/fonts/ElegantIcons.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/ElegantIcons.ttf') format('truetype');
    src:url('/assets/fonts/ElegantIcons.woff') format('woff');
}


// Import partials.
@import
  "plexi/mixins",
  "plexi/icons",
  "plexi/base",
  "plexi/layout",
  "plexi/home",
  "plexi/syntax-highlighting"
;
