/**
 * Site header
 */

$header-height: 64px;

.site-header {
    height: $header-height;
    padding: 0 $spacing-unit/2;

    // Positioning context for the mobile navigation icon
    position: fixed;
    left: 0;
    right: 0;
    z-index: 20;

    a {
	    @include typeset($heading-font-family,$heading-font-weight,1em, $header-height, -0.025em);
	    color: $white;
	    &:hover {
	    	text-decoration: none;
	    }
    }

    .wrapper {
    	display: flex;
    	flex-direction: row;
    	justify-content: space-between;
    }

    .site-title {
        $size: 48px; //ceil($header-height * .618);

        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: $size;
        height: $header-height;
        padding: 0;
        position: relative;
        z-index: 2;
        // font-size: 0 !important;
        color: transparent !important;
        overflow: visible;
        white-space: nowrap;

        background-image: url('/assets/plexi-logo-ondark.png');
        background-size: $size $size;
        background-position: left center;
        background-repeat: no-repeat;

        &,
        &:visited {
            color: $white;
        }
        @include media-query($on-palm) {
            flex-basis: 50%;
        }
    }

    .site-nav {
    	display: flex;
    	flex-basis: 100%;
    	justify-content: flex-end;
    	position: relative;
    	z-index: 1;

        .menu-icon {
            display: none;
        }

        .page-link, .social-link {
            // Gaps between nav items, but not on the last one
            &:not(:last-child) {
                margin-right: $spacing-unit;
            }
            &.active {
                @include opacity(.5);
                &:hover {
                    cursor: default;
                }
            }
        }

        @include media-query($on-palm) {
            flex-basis: 50%;
            .menu-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                height: $header-height;
                z-index: 3;

                >svg path {
                    fill: $white;
                }
            }

            .trigger {
                @include translate(-100vw,0);
                @include transition(opacity $animate-speed*2 $animate-equation);
                opacity: 0;
                clear: both;
                background: $brand-color;
                width: 100vw;
                height: 100vh;
                padding: $spacing-unit 0;
                box-sizing: border-box;
                position: fixed;
                left: 0;
                top: 0;//$header-height;
                justify-content: center;
                flex-direction: column;
                align-content: center;
                text-align: center;
                a {
                	color: $white;
                }
            }

            &.active {
                .trigger {
                    display: flex;
                    @include translate(0, 0);
                    @include opacity(100);
                }
            }

            .page-link,.social-link {
                display: block;
                padding: 0px 10px;
                margin: 0;
    	        &:not(:last-child) {
    	            margin-right: 0;
    	        }
            }
        }
    }

    &[isscrolled="true"] {
        // position: absolute;
        background: $white;
        @include box-shadow(0, 4px, 20px, rgba(0, 0, 0, 0.25), false);

        a {
            color: $brand-color;
        }

        .site-title {
            background-image: url('/assets/plexi-logo-onlight.png');
        }

        .site-nav {
            @include media-query($on-palm) {
                .menu-icon {
                    >svg path {
                        fill: $gray-100;
                    }
                }
                &.active {
                    .menu-icon {
                        >svg path {
                            fill: $white;
                        }
                    }

                }
            }
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    // border-top: 1px solid $grey-color-light;
    // padding: $spacing-unit 0;
    @include box-shadow(0, -4px, 20px, rgba(0, 0, 0, 0.25), false);
    height: 50px;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    // margin-left: -$spacing-unit / 2;
    @extend %clearfix;
    display: flex;
    flex-direction: row;
}

.footer-col {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    text-align: center;
    line-height: 1;

    p {
        margin: 0;
        line-height: 50px;
    }
}

@include media-query($on-palm) {
    .footer-col {
        display: block;
        width: 100%;
    }
}



/**
 * Page content
 */
.page-content {
    padding: (($spacing-unit * 2) + $header-height) 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    >li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin: 0 auto $spacing-unit auto;
    width: 36em;
}

// .post-title {
//     font-size: 42px;
//     letter-spacing: -1px;
//     line-height: 1;

//     @include media-query($on-laptop) {
//         font-size: 36px;
//     }
// }

.post-content {
    margin: 0 auto $spacing-unit auto;
    max-width: 36em;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
    * img {
        width: 100%;
        height: auto;
    }
}